import React, { useEffect, useState } from "react"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"
import StaticWeb from "../svg/static-website-svg"
import Server from "../svg/server-website-svg"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const [imgWidth, setImgWidth] = useState("")
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",

      },
    ],
  }

  useEffect(() => {
    setImgWidth(document.getElementById("svg-wrapper").offsetWidth - 32 + "px")
  }, [])
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | Static websites"
        description="Static websites: descripción del funcionamiento "
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <p className=" text-3xl">Static websites</p>
            </div>
          </div>
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="bg-white p-4 rounded-3xl" id="svg-wrapper">
                <div className="  w-fit mx-auto">
                  <StaticWeb width={imgWidth} />
                </div>
              </div>
              <div className="h-12" />
              <div className="text-left">
                <p>
                  Desplegamos nuestros sistemas a través de un CDN (Content
                  Delivery Network) que es un ambiente aislado. Puede recibir
                  datos de los servidores pero no puede enviar ningún dato a los
                  servidores. Esto mejora la seguridad.
                </p>
                <p>&nbsp;</p>
                <p>
                  De hecho, el servidor no está en funcioamineto todo el tiempo,
                  solo cuando se necesita una actualización. Entonces, el
                  servidor se inicia, procesa las actualizaciones, genera un
                  nuevo lote de archivos optimizados (html, css y javascript),
                  envía estos archivos a la CDN y se apaga. Esto mejora la
                  eficiencia energética y reduce la huella de CO2. Esto también
                  reduce los costes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Las actualizaciones del website se pueden realizar mediante
                  programación o cambiando la base de datos. Para cambiar la
                  base de datos existe un CMS (Content Management System) que
                  realiza operaciones CRUD (create, update and delete).
                </p>
                <p>&nbsp;</p>
                <p>
                  Una CDN puede responder a cientos de miles de solicitudes por
                  segundo. Por lo tanto, incluso en los picos de alto uso, nadie
                  se queda fuera. Eso es escalabilidad.
                </p>
                <p>&nbsp;</p>
                <p>
                  Un CDN con archivos optimizados atiende solicitudes mucho más
                  rápido que un servidor. Esto mejora la experiencia del usuario
                  y la clasificación SEO (Search Engine Optimization).
                </p>
                <p>&nbsp;</p>
                <p>¿Por qué es esto diferente?</p>
              </div>
            </div>
          </div>
          <div className="h-20" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <p className=" text-3xl">Websites basados en servidor</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="h-12" />
              <div className="text-left">
                <p>
                  Estos son los websites desarrollados con sistemas como WordPress o Drupal CMS.
                </p>
                <p>&nbsp;</p>
                <p>Ya no trabajamos con estos sistemas.</p>
              </div>
            </div>
          </div>
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="bg-white p-4 rounded-3xl" id="svg-wrapper">
                <div className="  w-fit mx-auto">
                  <Server width={imgWidth} />
                </div>
              </div>
              <div className="h-12" />
              <div className="text-left">
                <p>
                  Los sitios web basados en servidor necesitan potencia de
                  procesamiento para cada operación.
                </p>
                <p>&nbsp;</p>
                <p>
                  Como el CMS (Sistema de Gestión de Contenidos) está integrado
                  en el mismo servidor, el sistema es más susceptible a ataques.
                </p>
                <p>&nbsp;</p>
                <p>
                  Las actualizaciones son gestionadas por el CMS, que realiza
                  operaciones CRUD (crear, actualizar y eliminar) e
                  inmediatamente las hace disponibles en la base de datos.
                </p>
                <p>&nbsp;</p>
                <p>
                  Para cada solicitud que realiza un usuario, el sistema debe
                  acceder a la información en la base de datos y preparar, en
                  tiempo real, el conjunto de archivos (html, css y javascript)
                  para enviar al usuario. Esto requiere más tiempo, más potencia
                  de procesamiento y más energía eléctrica. En términos de
                  dinero, es más caro.
                </p>
                <p>&nbsp;</p>
                <p>
                  Otra desventaja de los sitios web basados en servidor es que
                  hay un límite de capacidad de procesamiento. Si muchos
                  usuarios intentan acceder al sitio web al mismo tiempo, el
                  tiempo de respuesta disminuye y el sistema incluso puede dejar
                  de responder.
                </p>
                <p>&nbsp;</p>
                <p>
                  Existen algunas estrategias para superar estos problemas, como
                  almacenar los archivos procesados en un área de caché o lanzar
                  automáticamente más servidores cuando la potencia de
                  procesamiento del primero supera un determinado límite. Pero
                  nunca será tan rápido, económico o seguro como un sitio web
                  estático.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
